


































import { Component, Vue } from 'vue-property-decorator';
import { dispatchCreateDistrito, dispatchGetDistritos } from '@/store/distrito/actions';
import { IDistritoCreate } from '@/interfaces/distrito';

@Component
export default class CadastrarDistrito extends Vue {
  public valid = false;
  public nome: string = '';

  public async mounted() {
    await dispatchGetDistritos(this.$store);
    this.reset();
  }

  public reset() {
    this.nome = '';
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const novoDistrito: IDistritoCreate = {
        nome: this.nome,
      };
      const cadastrou = await dispatchCreateDistrito(this.$store, novoDistrito);
      if (cadastrou) {
        this.$router.push('/main/distritos/listar');
      }
    }
  }
}
